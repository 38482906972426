var resizeTimer;
var mobile_trigger_width_xs = 1000;

$(window).bind("load", function () {
    initToTop();
    initEqualHeights();
    // initMaps();
    innitNav();
    innitHomepageSlider();
    initMap();
    initShowText();
    initPopup();

    // Resize
    $(window).resize(function () {
        clearTimeout($.data(this, 'resizeTimer'));
        $.data(this, 'resizeTimer', setTimeout(function () {
            innitNav();
            initEqualHeights();
        }, 100));
    });

});

//
// NAVIGATION
//
var innitNav = function () {

    // SHOW MOBILE OR DEKSTOP PMENU
    if ($(window).width() < mobile_trigger_width_xs) {

        const html = document.querySelector('html')

        $('#menu').slicknav({
            label: '',
            appendTo: '#mobilenav',
            easingOpen: "swing",
            easingClose: "swing",
            closedSymbol: '<i class="fa fa-chevron-right" aria-hidden="true"></i>',
            openedSymbol: '<i class="fa fa-chevron-down" aria-hidden="true"></i>'
        });
        const btn = document.querySelector('.slicknav_btn')

        console.log(btn)
        btn.addEventListener('click', function () {
            if (html.style.overflow === 'hidden') {
                html.style.overflow = 'initial';
            } else {
                html.style.overflow = 'hidden';
            }
        })
        $('nav').hide();
        $('.slicknav_menu').show();
    } else {
        $('nav').show();
        $('.slicknav_menu').hide();
    }
}


//
// BACK TO TOP
//
function initToTop() {
    $(document).scroll(function () {
        if ($(document).scrollTop() > 100) {
            $('.to-top').fadeIn('fast');

        } else {
            $('.to-top').fadeOut('fast');
        }
    });
    $('.to-top').click(function () {
        $('html, body').animate({
            scrollTop: 1
        }, 300);

        return false;
    });
}

//
// EQUAL HEIGHTS CONTENT BLOCKS
//
function initEqualHeights() {
    if ($('.equalHeights').length) {
        $('.equalHeights').each(function () {
            var height = 0,
                counter = 1,
                parent = $(this);


            $(this).find($(this).data('equals')).each(function () {
                if (parent.data('rows')) {
                    if (counter % parent.data('rows') === 0) {
                        height = 0;
                    }
                }

                if ($(this).outerHeight() > height) {
                    height = $(this).outerHeight();
                }

                counter++;
            });

            $($(this).data('equals')).css('min-height', height);
        });
    }

    if ($('.makeSquare').length) {
        $('.makeSquare').each(function () {
            $(this).css('height', ($(this).outerWidth() * .57));
        });
    }

    if ($('.makeSquareBig').length) {
        $('.makeSquareBig').each(function () {
            $(this).css('height', ($(this).outerWidth() * .57 - 1));
        });
    }

    if (screen.width > 1600) {
        console.log('test')
        if ($('.makeSquareBig').length) {
            $('.makeSquareBig').each(function () {
                $(this).css('height', ($(this).outerWidth() * .57 - .4));
            });
        }
    }

}

//
// GOOGLE MAPS
//
// function initMaps() {
//     if ($('#map').length > 0) {
//
//         var latlngs = $('#map').data('markers').split(';');
//         var latlng = new google.maps.LatLng();
//         var bounds = new google.maps.LatLngBounds();
//         var infoWindow = new google.maps.InfoWindow(), marker, i;
//         var infoWindowContent = [];
//
//
//         map = new google.maps.Map(document.getElementById("map"), {
//             zoom: 15,
//             mapTypeId: google.maps.MapTypeId.ROADMAP,
//             scrollwheel: false
//         });
//
//         for (var i = 0; i < latlngs.length; i++) {
//             var office = latlngs[i].split(',');
//             infoWindowContent.push('<div class="marker-content"> <h2 class="heading">' + office[2] + '</h2> <div class="body"> <p>' + office[3] + ' ' + office[4] + '<br>' + office[5] + ' ' + office[6] + '</p><p>T: <a href="tel:' + office[7] + '" title="Bel direct naar ' + office[2] + '">' + office[7] + '</a></p></div></div>');
//         }
//
//         for (var i = 0; i < latlngs.length; i++) {
//
//             var office = latlngs[i].split(',');
//             infoWindow = new google.maps.InfoWindow();
//
//             if (office[0] !== '') {
//
//                 latlng = new google.maps.LatLng(office[0], office[1]);
//
//                 // Create custom maker => Use map-icons http://map-icons.com/
//                 var marker = new Marker({
//                     map: map,
//                     position: latlng,
//                     icon: {
//                         path: MAP_PIN,
//                         fillColor: 'transparent',
//                         fillOpacity: 1,
//                         strokeColor: '',
//                         strokeWeight: 0
//                     },
//                     map_icon_label: '<span class="map-icon map-icon-postal-code"></span>'
//                 });
//
//
//                 // Add info window to marker
//                 google.maps.event.addListener(marker, 'click', (function (marker, i) {
//                     return function () {
//                         infoWindow.setContent(infoWindowContent[i]);
//                         infoWindow.open(map, marker);
//                     }
//                 })(marker, i));
//
//                 bounds.extend(marker.position);
//             }
//
//         }
//
//         map.fitBounds(bounds);
//     }
// }

//
// INVISIBLE CAPTCHA
//
function invisiblereCaptcha() {
    document.getElementById("frmContact").submit();
}


//
// SLIDER FOR HOMEPAGE
//
function innitHomepageSlider() {
    if ($('#homepage-services').length > 0) {
        $('#homepage-services .employees .wrapper').slick({
            dots: false,
            prevArrow: $('.icon-chevron-left'),
            nextArrow: $('.icon-chevron-right'),
            infinite: true,
            speed: 600,
            autoplay: true,
            autoplaySpeed: 6000,
            slidesToShow: 2,
            slidesToScroll: 2,

            responsive: [
                {
                    breakpoint: 1080,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },

                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
    }
}


function initMap() {
    if (document.querySelector('#contact')) {
        init()
    }

    function init() {


        var mapOptions = {
            zoom: 11,
            center: new google.maps.LatLng(51.856361, 4.425480), // New York
        };

        // Get the HTML DOM element that will contain your map
        // We are using a div with id="map" seen below in the <body>
        var mapElement = document.getElementById('map');

        // Create the Google Map using our element and options defined above
        var map = new google.maps.Map(mapElement, mapOptions);

        const icon = new google.maps.MarkerImage(
            '/img/Subtraction 2.svg',
            null, /* size is determined at runtime */
            null, /* origin is 0,0 */
            null, /* anchor is bottom center of the scaled image */
            new google.maps.Size(45, 45)
        );

        const icon1 = new google.maps.MarkerImage(
            '/img/Subtraction 1.svg',
            null, /* size is determined at runtime */
            null, /* origin is 0,0 */
            null, /* anchor is bottom center of the scaled image */
            new google.maps.Size(45, 45)
        );

        // Let's also add a marker while we're at it
        var marker1 = new google.maps.Marker({
            position: new google.maps.LatLng(51.838260, 4.123890),
            map: map,
            title: 'Snazzy!',
            icon: icon
        });

        // Let's also add a marker while we're at it
        var marker2 = new google.maps.Marker({
            position: new google.maps.LatLng(51.875260, 4.617330),
            map: map,
            title: 'Snazzy!',
            icon: icon1
        });
    }

}


function initShowText() {
    var text = document.querySelector("#employees .text .text")
    console.log(text)
    var buttons = document.querySelectorAll("#employees #ShowMoreButton")

    buttons.forEach(event => {
        event.addEventListener('click', e => {
            e.preventDefault();
            event.closest(".content").classList.toggle('show')
        })
    })

    // button.click(function (event) {
    //     event.preventDefault();
    //
    //     text.css("display", "block", "height", "auto")
    // })
}

function  initPopup() {

    if (getCookie('popupDisable') == 'true') {
        document.getElementById('popup').style.display = 'none';
    } else {
        document.getElementById('popup').classList.add('show')
    }

    if (document.getElementById('disablePopupButton')) {

        document.getElementById('disablePopupButton').addEventListener("click", function (e) {
            e.preventDefault();
            e.target.closest('#popup').classList.remove("show");

            document.cookie = 'popupDisable=true'
            console.log(document.cookie)

            console.log(getCookie('popupDisable'))
        })
    }

    function getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
}



